import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import '../components/App.css';
import parser from 'ua-parser-js';

import { MyLoadingOverlay } from '../components/NetworkingIndicator';
import { datadogLogs, Datacenter } from '@datadog/browser-logs';
import Button from '../components/Button';

type Browser = 'OldOs' | 'OldBrowser' | 'Ie' | 'Other';

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  eventOptions(event) {
    if (event.touches && event.touches.length > 1) {
      event.preventDefault();
    }
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.eventOptions, {
      capture: true,
      passive: false,
      once: true,
    } as EventListenerOptions);

    if (process.browser) {
      const ua = window.navigator.userAgent.toLowerCase();
      if (/(line)/.test(ua)) {
        // line
        if (location.href.indexOf('openExternalBrowser') === -1) {
          // 無限ループ防止
          location.href = '/?openExternalBrowser=1';
        }
      }

      datadogLogs.init({
        clientToken: 'pub616db9163e690f75fd6b8c171332c3e2',
        datacenter: Datacenter.US,
        forwardErrorsToLogs: true,
        sampleRate: 100,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.eventOptions, {
      capture: true,
      passive: false,
      once: true,
    } as EventListenerOptions);
  }

  checkBrowser(ua: parser.IResult): Browser | null {
    const webkitVersion = 605;
    const chromeVersion = 65;
    const geckoVersion = 68;

    if (process.browser) {
      switch (ua.os.name.toLowerCase()) {
        case 'android':
          if (ua.browser.name === 'Android Browser') {
            return 'OldOs';
          }
        case 'ios':
        case 'mac os':
          if (ua.engine.name === 'WebKit') {
            const version = ua.engine.version.match(/[0-9]{1,4}/)[0];
            if (Number(version) < webkitVersion) {
              return 'OldOs';
            }
          }
        case 'windows':
          if (ua.browser.name === 'IE' || ua.browser.name === 'Edge') {
            return 'Ie';
          }
        case 'linux':
          if (ua.browser.name === 'Chromium' || ua.browser.name === 'Chrome') {
            const version = ua.browser.version.match(/[0-9]{1,3}/)[0];
            if (Number(version) < chromeVersion) {
              return 'OldBrowser';
            }
          }
          if (ua.browser.name === 'Firefox') {
            const version = ua.engine.version.match(/[0-9]+/)[0];
            if (Number(version) < geckoVersion) {
              return 'OldBrowser';
            }
          }
          break;
      }

      if (/(yjapp)|(fban)|(fbios)|(cfnetwork)/.test(ua.browser.name.toLocaleLowerCase())) {
        return 'Other';
      }

      return /(chrome)|(chromium)|(firefox)|(safari)/.test(ua.browser.name.toLocaleLowerCase()) ? null : 'Other';
    }
  }

  isSystemMaintenance() {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    // console.log(currentDate);
    // console.log(currentHour);
    if (currentHour < 6) {
      // if (currentHour < 24) {
      return true;
    }
    return false;
  }

  openChrome() {
    const uri = window.location.host;
    window.location.href = `intent://${uri}#Intent;scheme=http;action=android.intent.action.VIEW;package=com.android.chrome;end`;
  }

  render() {
    const { Component, pageProps } = this.props;

    const res = component => (
      <React.Fragment>
        <Head>
          <meta
            key="meta-viewport"
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Head>
        <div>{component}</div>
        <MyLoadingOverlay />
      </React.Fragment>
    );

    const ua = (() => {
      if (process.browser) {
        return parser(window.navigator.userAgent);
      }
    })();
    const browser = this.checkBrowser(ua);

    // if (browser === 'OldOs') {
    //   return res(
    //     this.Message([
    //       '当WEBシステムは、最新のOSでの利用を想定しております。',
    //       'お手数ですがOSの更新をお願いいたします。',
    //       'ご了承くださいますようお願いいたします。',
    //     ]),
    //   );
    // }
    // if (browser === 'OldBrowser' || !ua) {
    //   return res(
    //     this.Message([
    //       '当WEBシステムは、最新のブラウザーでの利用を想定しております。',
    //       'お手数ですが最新のブラウザーをご利用ください。',
    //       'ご了承くださいますようお願いいたします。',
    //     ]),
    //   );
    // }
    if (browser === 'Ie') {
      return res(
        this.Message([
          '当WEBシステムは、スマートフォンでの利用を想定しております。',
          'EdgeやIEは動作対象外となります。',
          'ご了承くださいますようお願いいたします。',
        ]),
      );
    }
    // if (browser === 'Other') {
    //   const os = ua.os.name.toLowerCase();
    //   return res(
    //     this.Message([
    //       'このブラウザはサポートされていません。',
    //       'ご了承くださいますようお願いいたします。',
    //       os === 'ios' ? 'iOSの場合は、Google ChromeかSafariをご利用ください。' : '',
    //       `ご利用中のブラウザ: ${ua.browser.name}`,
    //       os === 'android' ? (<Button type="button" children="推奨ブラウザで開く" color="orange" onClick={() => this.openChrome()} />) : null,
    //     ])
    //   );
    // }
    if (this.isSystemMaintenance()) {
      return res(
        this.Message([
          '当WEBシステムは、0時〜6時までの間メンテナンスを実施いたします。',
          'メンテナンスの間は、システムを利用いただけません。',
          'ご了承くださいますようお願いいたします。',
        ]),
      );
    }
    return res(<Component {...pageProps} />);
  }

  Message = msg => (
    <div className="top">
      <div className="logo">
        <img src="/images/logo/logo.png?v=20240801" alt="Logo" />
      </div>
      <div className="buttons">
        {msg.map(msg => (
          <p key={msg}>{msg}</p>
        ))}
      </div>
      <style jsx global>{`
        body {
          padding: 0;
          margin: 0;
        }
      `}</style>
      <style jsx>{`
        .top {
          display: -webkit-box;
          display:-ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-pack: distribute;
          justify-content: space-around;
          height: 100vh;
          background-image: url('/images/background-large@2x.png?v=20240801');
          background-size: contain;
        }
        .logo {
          text-align: center;
        }
        .logo img {
          max-width: 100%;
        }
        .buttons {
          padding: 0 3em;
        }
        .buttons :global(button) {
          margin: 0.5em 0;
          display: block;
          width: 100%;
        }
        p {
          text-align: center;
        }
      `}</style>
    </div>
  );
}

export default MyApp;
